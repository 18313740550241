import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  children: ReactNode
}

const Section = styled.div`
  padding-top: 6rem;
  margin-bottom: 6rem;
`

const Title = styled.h3`
  margin-bottom: 4rem;
  text-transform: uppercase;
`

const StyleGuideSection = ({ title, children }: Props): JSX.Element => {
  return (
    <Section>
      <Title>{title}</Title>
      {children}
    </Section>
  )
}

export default StyleGuideSection
