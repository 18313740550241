import * as React from 'react'
import styled from 'styled-components'

interface SwatchProps {
  title: string
  colour: string
  variations?: Array<string>
  darkText?: boolean
}

interface SwatchStyleProps {
  darkText?: boolean
  colour: string
}

interface VariationProps {
  colour: string
}

const SwatchContainer = styled.div`
  height: 12rem;
  width: 22rem;
  background: ${(props: SwatchStyleProps): string => props.colour || '#fff'};
  color: ${(props: SwatchStyleProps): string =>
    props.darkText ? '#000' : '#fff'};
  border: ${(props: SwatchStyleProps): string =>
    props.darkText ? '1px solid #ccc' : '1px solid transparent'};
  border-radius: 0.5rem;
  overflow: hidden;
  margin-right: 1rem;
  margin-bottom: 1rem;
  font-size: 1.6rem;
`

const Labels = styled.div`
  padding: 1.2rem;
`

const SwatchInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const ColourText = styled.div`
  font-size: 1.2rem;
  text-transform: uppercase;
`

const VariationsContainer = styled.div`
  display: flex;
`

const Variation = styled.div`
  flex: 1;
  background: ${(props: VariationProps): string => props.colour || '#fff'};
`

/**
 * Specific component for Style Guide page to show swatch colours.
 */
const Swatch = (props: SwatchProps): JSX.Element => {
  const { title, colour, variations, darkText } = props
  return (
    <SwatchContainer colour={colour} darkText={darkText}>
      <SwatchInner>
        <Labels>
          <span>{title}</span>
          <ColourText>{colour}</ColourText>
        </Labels>
        <VariationsContainer role="group" className="swatch-variations">
          {variations &&
            variations.map((variation, index) => {
              return <Variation key={index} colour={variation}></Variation>
            })}
        </VariationsContainer>
      </SwatchInner>
    </SwatchContainer>
  )
}

export default Swatch
